$(document).on( 'scroll', function(){
  if ($(this).scrollTop() > 50) {
    $('#nav-container').addClass('nav-bar-transparent-back');
    $('#login-link').removeClass('text-white');
  }
  else {
    $('#nav-container').removeClass('nav-bar-transparent-back');
    $('#login-link').addClass('text-white');
  }
});

$(document).ready(function() {
  $('.banner-img-container').each(function(){
    console.log($(this).width());
    $(this).height(parseInt($(this).width()) * 579 / 1030 + 'px');
  })

  var heights = $('.description-container').map(function(){
    return parseInt($(this).height());
  })
  var max_height = Math.max.apply(Math, heights);
  $('.description-container').each(function(){
    $(this).height(max_height + 'px');
  })
})

$(window).on('resize', function(e){
  $('.banner-img-container').each(function(){
    console.log($(this).width());
    $(this).height(parseInt($(this).width()) * 579 / 1030 + 'px');
  })

  var heights = $('.description-container').map(function(){
    return parseInt($(this).height());
  })
  var max_height = Math.max.apply(Math, heights);
  $('.description-container').each(function(){
    $(this).height(max_height + 'px');
  })
})